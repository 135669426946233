import type { CSSProperties } from "react";
import React, { useCallback, useState } from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { iff, selectColor, selectSize } from "../../../utils/themeUtils";
import Modal from "./Modal";
import { RemoveScroll } from "react-remove-scroll";

const HAS_VIDEO_CHAT_BUTTON = true;

export interface BaseDialogProps {
  isOpen: boolean;
  close: () => void;
  noContentPadding?: boolean;
  renderHeader: () => React.ReactNode;
  innerCss?: FlattenSimpleInterpolation;
  contentStyle?: CSSProperties;
}

export const Content = styled.div<{ noPadding: boolean }>`
  overflow: auto;

  /* make sure text has enough line-height to avoid issue with visible scrollbars */
  line-height: 24px;

  ${iff((p) => !p.noPadding)`
    margin-top: 10px;
    padding-left: ${selectSize("dialogPadding")}px;
    padding-right: ${selectSize("dialogPadding")}px;
  `}

  padding-bottom: ${HAS_VIDEO_CHAT_BUTTON ? "70px" : "15px"};
  height: 100%;

  ${mediaQueries.desktop`
 
    /* because of some special contents, we have to compensate with negative margin, instead of 0 padding */
    margin-left: -${selectSize("dialogPadding")}px;
    margin-right: -${selectSize("dialogPadding")}px;
    padding-bottom: 15px;
  `}
`;

export const ModalInner = styled.div<{ innerCss?: FlattenSimpleInterpolation }>`
  display: flex;
  flex-direction: column;
  background-color: ${selectColor("white")};
  width: 100%;
  height: 100%;
  max-height: calc(100vh);
  max-width: calc(100vw);
  padding: 0;

  ${mediaQueries.desktop`
    border-radius: 5px;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    padding: ${selectSize("dialogPadding")}px;
    background-color: ${selectColor("sand")};
  `}

  ${(p) => p.innerCss}
`;

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    setIsOpen,
    close,
  };
};
const MODAL_STYLE = {
  top: "30%",
  left: "50%",
  transform: `translate(-50%, -30%)`,
};
const BaseDialog: React.FC<BaseDialogProps> = ({
  noContentPadding = false,
  isOpen,
  close,
  renderHeader,
  innerCss,
  children,
  contentStyle,
}) => {
  const preventPropagation = useCallback((e) => e.stopPropagation(), []);
  return (
    <RemoveScroll enabled={isOpen} allowPinchZoom>
      <Modal showOverlay isOpen={isOpen} onClick={close} style={MODAL_STYLE}>
        <ModalInner innerCss={innerCss} onClick={preventPropagation}>
          {renderHeader?.()}
          <Content noPadding={noContentPadding} style={contentStyle}>
            {children}
          </Content>
        </ModalInner>
      </Modal>
    </RemoveScroll>
  );
};

export default BaseDialog;
