import type { CSSProperties } from "react";
import React from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import {
  iff,
  selectColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import CloseIcon from "../../icons/components/CloseIcon";
import BaseDialog from "./BaseDialog";

export type DialogType = "normal" | "error";
export interface DialogProps {
  isOpen: boolean;
  close: () => void;
  title?: string;
  type?: DialogType;
  innerCss?: FlattenSimpleInterpolation;
  noContentPadding?: boolean;
  contentStyle?: CSSProperties;
}

export const ButtonIcon = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 10;
  padding: 7px;
  margin-right: -7px;

  svg {
    height: 18px;
    g {
      stroke: ${selectColor("white")};
    }
  }

  ${mediaQueries.desktop`
    svg {
      height: 12px;
      g {
        stroke: ${selectColor("black")};
      }
    }
  `}
`;

export const Title = styled.div<{ type?: DialogType }>`
  flex-grow: 1;
  text-transform: uppercase;
  ${selectFont("dialogTitle")}
  display: flex;
  align-items: center;

  ${iff((p) => p.type === "error")`
    color: ${selectColor("white")};

    ${mediaQueries.desktop`
      color: ${selectColor("veloplusRot")};
    `}
  `}

  ${mediaQueries.desktop`
    text-transform: none;
  `}
`;

export const Header = styled.div<{ type?: DialogType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${selectSize("dialogPadding")}px;
  height: 55px;
  background: ${selectColor("dark")};
  color: ${selectColor("white")};

  ${iff((p) => p.type === "error")`
    background: ${selectColor("veloplusRot")};
  `}

  ${mediaQueries.desktop`
    align-items: stretch;
    padding: 0;
    height: auto;
    background: transparent;
    color: ${selectColor("black")};
  `}
`;

const Dialog: React.FC<DialogProps> = ({
  noContentPadding = false,
  isOpen,
  close,
  title,
  type = "normal",
  innerCss,
  children,
  contentStyle,
}) => {
  return (
    <BaseDialog
      noContentPadding={noContentPadding}
      isOpen={isOpen}
      close={close}
      innerCss={innerCss}
      contentStyle={contentStyle}
      renderHeader={() => (
        <Header type={type}>
          <Title type={type}>{title}</Title>
          <ButtonIcon onClick={() => close()}>
            <CloseIcon />
          </ButtonIcon>
        </Header>
      )}
    >
      {children}
    </BaseDialog>
  );
};

export default Dialog;
