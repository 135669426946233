import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectZIndex } from "../../../utils/themeUtils";
import CloseIcon from "../../icons/components/CloseIcon";
import type { PopupAlignment } from "../hooks/usePopupPosition";
import usePopupPosition, {
  POPUP_ARROW_SQUARE_SIZE,
} from "../hooks/usePopupPosition";
import { Content, ModalInner, useDialog } from "./BaseDialog";
import { ButtonIcon, Header, Title } from "./Dialog";

import Modal from "./Modal";

type Theme = "sand" | "white";

export interface PopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsHovered?: (isHovered: boolean) => void;
  title?: string;
  disabled?: boolean;
  isDialog?: boolean;
  parentRef: React.MutableRefObject<HTMLElement>;
  popupAlignment?: PopupAlignment;
  theme?: "white" | "sand";
  noContentPadding?: boolean;
}

const PopupInner = styled(ModalInner)<{
  popupTheme: Theme;
  position?: React.CSSProperties;
}>`
  background-color: ${selectColor((p) => p.popupTheme)};

  ${mediaQueries.desktop`
    background-color: ${selectColor((p) => p.popupTheme)};

    & > ${ButtonIcon} {
      display: ${(p) => (p.isModal ? "block" : "none")};
    }

    ${(p) => (p.position ? p.position : {})}
  `}
`;

const PopupArrow = styled.div<{ popupTheme: Theme }>`
  display: none;
  background: ${selectColor((p) => p.popupTheme)};

  ${mediaQueries.desktop`
    display: block;
    min-height: ${POPUP_ARROW_SQUARE_SIZE}px;
    min-width: ${POPUP_ARROW_SQUARE_SIZE}px;
    max-height: ${POPUP_ARROW_SQUARE_SIZE}px;
    max-width: ${POPUP_ARROW_SQUARE_SIZE}px;
  `}
`;

const ParentOverlay = styled.div`
  position: fixed;
  display: none;
  cursor: pointer;

  ${mediaQueries.desktop`
    display: flex;
    z-index: ${selectZIndex("popupParentOverlay")};

    & > div {
      transform: rotate(225deg);
    }
  `}
`;

export function usePopup<T extends HTMLElement>() {
  const triggerRef = useRef<T>();
  const { isOpen, setIsOpen, close } = useDialog();

  return {
    isOpen,
    setIsOpen,
    close,
    triggerRef,
  };
}

const PopupModal = styled(Modal)`
  display: flex;
  flex-direction: row;
`;

const Popup: React.FC<PopupProps> = ({
  isOpen,
  setIsOpen,
  setIsHovered,
  title,
  disabled,
  isDialog,
  noContentPadding = false,
  parentRef,
  popupAlignment = "HORIZONTAL",
  theme = "sand",
  children,
}) => {
  if (process.browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const currentEl = parentRef.current;

      const onMouseEnter = () => !isDialog && !disabled && setIsOpen(true);
      const onClick = (e) => {
        if (!disabled) {
          e.preventDefault();
          setIsOpen(true);
        }
      };
      if (currentEl) {
        currentEl.addEventListener("click", onClick);
        if (!isDialog) {
          currentEl.addEventListener("mouseenter", onMouseEnter);
        }
      }
      return () => {
        if (currentEl) {
          currentEl.removeEventListener("click", onMouseEnter);
          currentEl.removeEventListener("mouseenter", onMouseEnter);
        }
      };
    }, [parentRef, isDialog, disabled, setIsOpen]);
  }

  const [isModal, setIsModal] = useState(isDialog);

  const close = useCallback(() => {
    if (!isDialog) {
      setIsModal(false);
    }
    setIsOpen(false);
  }, [isDialog, setIsOpen]);

  const onHover = useCallback(() => {
    if (!isModal) {
      close();
    }
  }, [isModal, close]);
  const {
    ref,
    styleAdjustments: { position, parentOverlay, inner },
  } = usePopupPosition(popupAlignment, parentRef.current, isOpen);

  const renderModalElements = useCallback(
    () => (
      <ParentOverlay style={parentOverlay} onClick={() => setIsModal(true)}>
        <PopupArrow popupTheme={theme} />
      </ParentOverlay>
    ),
    [parentOverlay, theme],
  );
  return (
    <RemoveScroll enabled={isOpen} allowPinchZoom>
      <PopupModal
        showOverlay={isModal || isDialog}
        isOpen={isOpen}
        onMouseOver={onHover}
        onClick={close}
        style={position}
        renderModalElements={renderModalElements}
      >
        <PopupInner
          onClick={(e) => e.stopPropagation()}
          position={inner}
          popupTheme={theme}
          ref={ref}
          onMouseEnter={() => {
            setIsHovered?.(true);
          }}
          onMouseLeave={() => {
            setIsHovered?.(false);
          }}
        >
          <Header>
            <Title>{title}</Title>
            <ButtonIcon onClick={() => close()}>
              <CloseIcon color="white" />
            </ButtonIcon>
          </Header>
          <Content noPadding={noContentPadding}>{children}</Content>
        </PopupInner>
        <ParentOverlay />
      </PopupModal>
    </RemoveScroll>
  );
};

export default Popup;
