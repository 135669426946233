import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
export type PopupAlignment = "VERTICAL" | "HORIZONTAL";

export const POPUP_ARROW_SQUARE_SIZE = 20;
export const POPUP_PADDING = 20;

const windowWidth = () => (document ? document.documentElement.clientWidth : 0);
const windowHeight = () =>
  document ? document.documentElement.clientHeight : 0;

const getRect = <T extends Element>(
  element?: T,
): DOMRect | DOMRectReadOnly | ClientRect => {
  return element ? element.getBoundingClientRect() : null;
};

const getVerticalAdjustments = (
  parentRect: DOMRect | ClientRect,
  innerRect: DOMRect | ClientRect,
) => {
  const position: React.CSSProperties = {
    width: "auto",
  };

  const popupHorizontalPosition =
    parentRect.left > windowWidth() - parentRect.right ? "RIGHT" : "LEFT";
  const popupPosition =
    windowHeight() - parentRect.bottom < parentRect.top ? "TOP" : "BOTTOM";

  if (popupPosition === "TOP") {
    position.bottom = windowHeight() - parentRect.top + POPUP_ARROW_SQUARE_SIZE;
    position.alignItems = "flex-end";
  } else {
    position.top = parentRect.bottom + POPUP_ARROW_SQUARE_SIZE;
  }

  if (popupHorizontalPosition === "LEFT") {
    position.left =
      innerRect.width / 2 < parentRect.left + parentRect.width / 2
        ? (position.left =
            parentRect.left + parentRect.width / 2 - innerRect.width / 2)
        : POPUP_PADDING;
  } else {
    position.right =
      windowWidth() - parentRect.right - parentRect.width / 2 >
      innerRect.width / 2
        ? windowWidth() -
          parentRect.left -
          parentRect.width / 2 -
          innerRect.width / 2
        : POPUP_PADDING;
  }

  const parentOverlay: React.CSSProperties = {
    top:
      popupPosition === "TOP"
        ? parentRect.top - POPUP_ARROW_SQUARE_SIZE / 2 - POPUP_ARROW_SQUARE_SIZE
        : parentRect.top,
    left: parentRect.left,
    width: parentRect.width,
    height:
      parentRect.height -
      POPUP_ARROW_SQUARE_SIZE / 2 +
      POPUP_ARROW_SQUARE_SIZE * 2,
    alignItems: popupPosition === "TOP" ? "flex-start" : "flex-end",
    justifyContent: "center",
  };

  const inner: React.CSSProperties = {
    width: "auto",
    height: "auto",
    maxHeight:
      popupPosition === "TOP"
        ? `calc(100vh - ${
            windowHeight() +
            POPUP_PADDING -
            parentRect.top +
            POPUP_ARROW_SQUARE_SIZE
          }px)`
        : `calc(100vh - ${
            parentRect.bottom + POPUP_PADDING + POPUP_ARROW_SQUARE_SIZE
          }px)`,
  };

  return {
    position,
    parentOverlay,
    inner,
  };
};

const getHorizontalStyleAdjustments = (
  parentRect: DOMRect | ClientRect,
  innerRect: DOMRect | ClientRect,
) => {
  const position: React.CSSProperties = {
    display: "flex",
    bottom: "auto",
    top: parentRect.top + parentRect.height / 2 - innerRect.height / 2,
  };

  const popupPosition =
    parentRect.left < windowWidth() - parentRect.right ? "RIGHT" : "LEFT";

  if (popupPosition === "RIGHT") {
    position.right = POPUP_PADDING;
    position.width =
      windowWidth() -
      parentRect.right -
      POPUP_PADDING -
      POPUP_ARROW_SQUARE_SIZE;
    position.justifyContent = "flex-start";
  } else {
    position.left = POPUP_PADDING;
    position.width = parentRect.left - POPUP_PADDING - POPUP_ARROW_SQUARE_SIZE;
    position.justifyContent = "flex-end";
  }

  if (innerRect.height / 2 > parentRect.top) {
    position.top = POPUP_PADDING;
    position.bottom = "auto";
  } else if (Number(position.top) + innerRect.height > windowHeight()) {
    position.top = "auto";
    position.bottom = POPUP_PADDING;
  }

  const parentOverlay: React.CSSProperties = {
    top: parentRect.top,
    height: parentRect.height,
    alignItems: "center",
    justifyContent: popupPosition === "RIGHT" ? "flex-end" : "flex-start",
  };

  if (popupPosition === "RIGHT") {
    parentOverlay.left = parentRect.left;
    parentOverlay.width =
      parentRect.width + POPUP_ARROW_SQUARE_SIZE + POPUP_ARROW_SQUARE_SIZE / 2;
  } else {
    parentOverlay.left =
      parentRect.left - POPUP_ARROW_SQUARE_SIZE - POPUP_ARROW_SQUARE_SIZE / 2;
    parentOverlay.width = parentRect.width + POPUP_ARROW_SQUARE_SIZE * 2;
  }

  const inner: React.CSSProperties = {
    width: "auto",
    maxWidth:
      popupPosition === "RIGHT"
        ? `calc(100vw - ${
            parentRect.right + POPUP_PADDING + POPUP_ARROW_SQUARE_SIZE
          }px)`
        : `calc(100vw - ${
            windowWidth() +
            POPUP_PADDING -
            parentRect.left +
            POPUP_ARROW_SQUARE_SIZE
          }px)`,
  };
  return {
    position,
    parentOverlay,
    inner,
  };
};

const getStyleAdjustments = (
  popupPosition: PopupAlignment,
  parentRect: DOMRect | ClientRect,
  innerRect: DOMRect | ClientRect,
): {
  position: React.CSSProperties;
  parentOverlay: React.CSSProperties;
  inner: React.CSSProperties;
  corrections?: {
    position: React.CSSProperties;
    parentOverlay: React.CSSProperties;
    inner: React.CSSProperties;
  };
} => {
  return popupPosition === "VERTICAL"
    ? getVerticalAdjustments(parentRect, innerRect)
    : getHorizontalStyleAdjustments(parentRect, innerRect);
};

const NO_STYLE: StyleAdjustments = {
  position: null,
  parentOverlay: null,
  inner: null,
};

interface StyleAdjustments {
  position: React.CSSProperties;
  parentOverlay: React.CSSProperties;
  inner: React.CSSProperties;
}

const usePopupPosition = (
  popupPosition: PopupAlignment,
  parentElement: HTMLElement,
  isOpen: boolean,
) => {
  const ref = useRef();
  const [innerRect, setInnerRect] = useState<
    DOMRect | DOMRectReadOnly | ClientRect
  >();
  const [parentRect, setParentRect] = useState<
    DOMRect | DOMRectReadOnly | ClientRect
  >();

  useEffect(() => {
    if (isOpen) {
      setInnerRect(getRect(ref.current));
      setParentRect(getRect(parentElement));
    }
  }, [parentElement, isOpen]);

  const styleAdjustments = useMemo(
    () => {
      const adjust =
        parentRect && innerRect
          ? getStyleAdjustments(popupPosition, parentRect, innerRect)
          : NO_STYLE;

      return adjust;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popupPosition, JSON.stringify({ innerRect, parentRect })],
  );

  if (process.browser) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      if (!isOpen) return;
      const ro = new ResizeObserver(() => {
        setInnerRect(getRect(ref.current));
        setParentRect(getRect(parentElement));
      });

      if (ref.current) {
        ro.observe(ref.current);
      }

      if (parentElement) {
        ro.observe(parentElement);
      }

      return () => ro && ro.disconnect();
    }, [isOpen, ref, parentElement]);
  }

  return {
    ref,
    styleAdjustments,
  };
};

export default usePopupPosition;
