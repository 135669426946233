import type { MouseEventHandler } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import type { TransitionStatus } from "react-transition-group/Transition";
import styled, { css } from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { iff, selectZIndex } from "../../../utils/themeUtils";

const modalRoot = process.browser && document.body;

const ModalBase = styled.div<{ state: TransitionStatus }>`
  position: relative;
  z-index: ${selectZIndex("modalContainer")};
  display: none;
  opacity: 0;
  transition: opacity 0.25s linear;

  ${iff((p) => p.state === "entering")`
    display: block;
  `}

  ${iff((p) => p.state === "entered")`
    display: block;
    opacity: 1;
  `}

  ${iff((p) => p.state === "exiting" || p.state === "exited")`
    display: block;
    opacity: 0;
  `}
`;

const Overlay = styled.div<{ hide: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${({ hide }) =>
    hide ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.5)"};
  height: 100vh;
  width: 100vw;
  z-index: ${selectZIndex("modalOverlay")};
`;

const ModalContent = styled.div<{ desktopStyle?: React.CSSProperties }>`
  position: fixed;
  z-index: ${selectZIndex("modalContent")};

  display: flex;
  flex-direction: row;

  left: 0px;
  top: 0;
  width: 100%;
  bottom: 0;

  ${mediaQueries.desktop`
    left: auto;
    height: auto;
    width: auto;
    bottom: auto;
    ${(p) => p.desktopStyle && css(p.desktopStyle)}
  `}
`;

export default class Modal extends React.PureComponent<{
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
  isOpen: boolean;
  el?: Element;
  showOverlay?: boolean;
  renderModalElements?: () => React.ReactNode;
}> {
  private el: Element;

  constructor(props) {
    super(props);
    this.el = process.browser
      ? props.el
        ? props.el
        : document.createElement("div")
      : null;
  }

  public componentDidMount() {
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  public componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  public render() {
    if (!this.el) {
      return null;
    }

    return ReactDOM.createPortal(
      // tslint:disable-next-line: jsx-wrap-multiline
      <Transition
        mountOnEnter
        unmountOnExit
        timeout={{ exit: 300 }}
        in={this.props.isOpen}
      >
        {(state) => (
          <ModalBase state={state}>
            {this.props.renderModalElements
              ? this.props.renderModalElements()
              : null}
            <ModalContent
              onClick={this.props.onClick}
              desktopStyle={this.props.style}
            >
              {this.props.children}
            </ModalContent>
            <Overlay
              onMouseOver={this.props.onMouseOver}
              onClick={this.props.onClick}
              hide={!this.props.showOverlay}
            />
          </ModalBase>
        )}
      </Transition>,
      this.el,
    );
  }
}
